:root {
  --cyan: #29C7C7;
  --darkviolet: hsl(257, 27%, 26%);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');


body {
  font-family: Poppins;
}

.btn-copy,
.btn-shorten {
  border-radius: 0.5rem;
  background-color: var(--cyan);
  color: white;
}

.btn-copy.active {
  background-color: var(--darkviolet);
}

.btn-shorten {
  font-size: 1.1rem;
  padding: 1rem 2rem;
}

.btn-primary:hover,
.btn-get-started:hover,
.btn-copy:hover,
.btn-shorten:hover {
  -moz-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
}

.vertical-bg {
  background: linear-gradient(#29C7C7, #29C7C7) no-repeat center/10px 100%;
}

/* Animations for new links */
@keyframes append-animate {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;	
	}
}

.opacity-animation {
	animation: append-animate .3s linear;
}